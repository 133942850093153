import Dropdown from "./dropdown";
import GraduatesDropdown from "./dropdown-graduates";

export default class SiteHeader {

  headerWrapper: HTMLElement | null;

  //dropdowns
  yearsList: Dropdown;
  departmentsList: Dropdown;
  graduatesList: GraduatesDropdown;

  //foldable main menu
  lastScrollTop: number;
  scrollHandler: EventListener;
  resizeHandler: EventListener;

  //dropdowns
  clickHandler: EventListener;
  keyboardHandler: EventListener;

  constructor(id: string = 'site-header') {

    this.headerWrapper = document.getElementById(id);
    this.yearsList = new Dropdown('years');
    this.departmentsList = new Dropdown('departments');
    this.graduatesList = new GraduatesDropdown('graduates');

    this.clickHandler = this.handleClickEvent.bind(this);
    this.keyboardHandler = this.handleKeyboardEvent.bind(this);
    this.scrollHandler = this.handleScrollEvent.bind(this);
    this.resizeHandler = this.handleResizeEvent.bind(this);

    this.lastScrollTop = document.documentElement.scrollTop || document.body.scrollTop;

		this.enable();
	}

  enable() {
    window.addEventListener('click', this.clickHandler, false);
    window.addEventListener('keydown', this.keyboardHandler, false);
    window.addEventListener('scroll', this.scrollHandler, false);
    window.addEventListener('resize', this.resizeHandler, false);
  }

  destroy() {
    window.removeEventListener('click', this.clickHandler);
    window.removeEventListener('keydown', this.keyboardHandler);
    window.removeEventListener('scroll', this.scrollHandler);
    window.removeEventListener('resize', this.resizeHandler);
  }

  handleScrollEvent(e: Event) {

    const threshold = 0;
    let currentScrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    let amount = currentScrollTop - this.lastScrollTop;
    
    if(
      currentScrollTop <= 0 //we are on top
      ||
      amount < (threshold * -1)
    ) {
      this.lastScrollTop = currentScrollTop;
      document.body.classList.remove('scrolled-down');
    } else if(amount > threshold) {
      this.lastScrollTop = currentScrollTop;
      document.body.classList.add('scrolled-down');
    }
  }

  handleResizeEvent(e: Event) {
    document.body.classList.remove('scrolled-down');
  }

  handleClickEvent(e: MouseEvent) {
  
    const target = e.target as HTMLElement;
    switch(target?.id) {

      case 'button-open-graduates':
        this.departmentsList.close();
        this.yearsList.close();
        this.graduatesList.toggle();
        break;
  
      case 'button-open-years': 
        this.graduatesList.close(); 
        this.departmentsList.close();
        this.yearsList.toggle();
        break;

      case 'button-open-departments': 
        this.graduatesList.close(); 
        this.yearsList.close(); 
        this.departmentsList.toggle();
        break;

      case 'years-list':
        this.yearsList.close();
        break;

      case 'departments-list':
        this.departmentsList.close();
        break;
      
      case 'graduates-list':
        this.graduatesList.close();
        break;

      case 'button-scroll-top':
        window.scrollTo(0, 0);
        break;

      case 'home-link':

        const homeLink = target as HTMLLinkElement;

        if (this.hasOpenDropdowns()) {

          e.preventDefault();
          this.closeAllDropdowns();

        } else if(homeLink.href == window.location.href) {
          
          e.preventDefault();
          if(!this.hasOpenDropdowns())
          {
            window.scrollTo(0,0);
          }
        }
        break;
    }
  };

  handleKeyboardEvent(e: KeyboardEvent) {

    switch (e.key) {

      case "Escape":
        this.yearsList.close();
        this.graduatesList.close();
        this.departmentsList.close();
        break;
    }
  }

  hasOpenDropdowns() {
    return (
      this.departmentsList.isOpen
      || this.graduatesList.isOpen
      || this.yearsList.isOpen
    );
  }

  closeAllDropdowns() {
    this.departmentsList.close()
    this.graduatesList.close()
    this.yearsList.close()
  }
}