import Swiper from "swiper";
import { Graduate, GraduateContent, GraduateCover } from "./graduate";


export default class GraduatePager {

  wrapperElement: HTMLElement | null;
  clickHandler: EventListener;
  popStateHandler: EventListener;

  prevPageLink: HTMLAnchorElement | null;
  nextPageLink: HTMLAnchorElement | null;

  allPages: Array<Graduate>;
  prevPage: Graduate;
  nextPage: Graduate;
  currentPage: Graduate;

  swiper: Swiper;
  mainElement: HTMLElement | null;
  galleryWrapper: HTMLElement | null;
  titleElement: HTMLElement | null;
  textElement: HTMLElement | null;
  cvElement: HTMLElement | null;
  websiteElement: HTMLElement | null;
  
  homeLink: HTMLElement | null;
  homeLinkYear: HTMLElement | null;
  switchLangLink: HTMLElement | null;

  constructor(id: string, swiper: Swiper) {

    this.wrapperElement = document.getElementById(id);
    this.swiper = swiper;

    //also assigns currentPage
    this.allPages = this.loadGraduatesFromTable();
    this.prevPage = getPrevOrLastPage(this.allPages, this.currentPage);
    this.nextPage = getNextOrFirstPage(this.allPages, this.currentPage);

    if(this.wrapperElement) {

      this.prevPageLink = this.wrapperElement.querySelector('#prev-page-link');
      this.nextPageLink = this.wrapperElement.querySelector('#next-page-link');
      this.mainElement = document.getElementById('main');

      if(
        this.prevPageLink instanceof HTMLAnchorElement
        && this.nextPageLink instanceof HTMLAnchorElement
        && this.mainElement instanceof HTMLElement
      ) {

        this.galleryWrapper = document.getElementById('gallery');
        this.titleElement = document.getElementById('page-title');
        this.textElement = document.querySelector('.section-annotation');
        this.cvElement = document.querySelector('.section-cv');
        this.websiteElement = document.querySelector('.section-websites');

        this.homeLink = document.getElementById('home-link');
        this.homeLinkYear = document.getElementById('home-link-year');
        this.switchLangLink = document.getElementById('switch-language-link');

        this.clickHandler = this.handleClickEvent.bind(this);
        this.popStateHandler = this.handlePopStateEvent.bind(this);
        this.enable();
      }
    }
  }

  loadGraduatesFromTable(): Array<Graduate> {

    let rows = document.querySelectorAll('.graduates-table tbody tr');

    return Array.from(rows, (row) => {

      let nameLink = row.querySelector('.name a') as HTMLAnchorElement;
      let yearLink = row.querySelector('.year a') as HTMLAnchorElement;
      let departmentLink = row.querySelector('.department a') as HTMLAnchorElement;

      let graduate = new Graduate({
        rowElement: row,
        index: row.getAttribute('data-index'),
        url: nameLink.href, //pro fetch
        title: nameLink.getAttribute('data-title'), //label aktualni stranky nezavisla na fetchi
        year: yearLink.text, //kvuli stylum,
        department: departmentLink.href
      });

      if(nameLink.getAttribute('aria-current')) {
        this.currentPage = graduate;
      }

      return graduate;
    });
  }

  enable() {

    window.addEventListener('click', this.clickHandler);
    window.addEventListener('popstate', this.popStateHandler);

    this.prefetchPrevNext();
  }

  destroy() {
    window.removeEventListener('click', this.clickHandler);
    window.removeEventListener('popstate', this.popStateHandler);
  }

  async prefetchPrevNext() {

    try {
      await this.nextPage?.getContent();
      await this.prevPage?.getContent();
      this.nextPage?.prefetchCover();
      this.prevPage?.prefetchCover();
    } catch (error) {
      return false;
    }
  }

  async goToNext() {
    this.render(this.nextPage, true);
  }

  async goToPrev() {
    this.render(this.prevPage, true);
  }

  async render(newGraduate: Graduate, pushState: boolean = false) {

    setLoading();

    if(pushState) {
      history.pushState({}, "", newGraduate.url);
    }
    
    window.scrollTo(0, 0);

    //cross year transition
    let oldYearAttr = document.body.getAttribute('data-year') as string;
    if(oldYearAttr != newGraduate.year) {

      let oldYearClass = 'year-' + oldYearAttr;
      let newYearClass = 'year-' + newGraduate.year;
      
      document.body.setAttribute('data-year', newGraduate.year);
      document.body.classList.remove(oldYearClass);
      document.body.classList.add(newYearClass);
      document.querySelector('#years-list [aria-current]')?.removeAttribute('aria-current');
      document.querySelector('#years-list .' + newYearClass)?.setAttribute('aria-current', 'location');

      this.homeLink!.setAttribute('href', this.homeLink!.getAttribute('href')!.replace(oldYearAttr, newGraduate.year));
      this.homeLinkYear!.innerHTML = newGraduate.year;
    }

    //set new status quo
    this.currentPage = newGraduate;
    this.prevPage = getPrevOrLastPage(this.allPages, this.currentPage);;
    this.nextPage = getNextOrFirstPage(this.allPages, this.currentPage);
    this.prevPageLink!.href = this.prevPage.url;
    this.nextPageLink!.href = this.nextPage.url;

    // RENDER CONTENT //

    //change page header
    this.titleElement!.textContent = newGraduate.title;

    //change document title
    let splitter = ' | '
    let oldTitle = document.title;
    let titleArray = oldTitle.split(splitter);
    titleArray[0] = newGraduate.title;
    document.title = titleArray.join(splitter);

    //load asynchronously if not loaded already
    if(!newGraduate.content) {

      try {
        newGraduate.content = await newGraduate.getContent()
      } catch (error) {
        window.location.reload();
        return;
      }

      //ignore if not relevant anymore
      if(this.currentPage.url !== newGraduate.url) {
        return;
      }
    }
    
    //switch content of the page
    this.websiteElement?.remove();
    this.websiteElement = htmlToHTMLElement(newGraduate.content.websites);
    this.mainElement?.append(this.websiteElement ?? '');

    this.cvElement?.remove();
    this.cvElement = htmlToHTMLElement(newGraduate.content.studyCv);
    this.mainElement?.append(this.cvElement ?? '');

    this.textElement?.remove();
    this.textElement = htmlToHTMLElement(newGraduate.content.text);
    this.mainElement?.append(this.textElement ?? '');

    this.galleryWrapper!.innerHTML = newGraduate.content.gallery;
    this.swiper.update();
    this.swiper.slideTo(0,0);
    this.switchLangLink!.setAttribute('href', newGraduate.content.langLink);

    let firstSlide = this.galleryWrapper!.querySelector('.swiper-slide:first-child img');

    if(firstSlide) {
      //show content but keep loader
      document.body.classList.add('get-ready');
      //hide loader on first slide full load
      firstSlide.addEventListener('load', () => {
        unsetLoading()
      });
    } else {
      unsetLoading();
    }

    this.prefetchPrevNext();
  }

  fetchGraduate = async (url: string): Promise<Graduate> => {

    const response = await fetch(url + '.json');
    const jsonData = await response.json();

    let graduate = new Graduate(jsonData);
    graduate.prefetchCover();

    return graduate;
  }

  handlePopStateEvent() {

    let newPageUrl = location.href;
    let currentPage = getPageByUrl(this.allPages, newPageUrl);

    if (currentPage) {
      this.render(currentPage);
      this.currentPage = currentPage;
      this.nextPage = getNextOrFirstPage(this.allPages, this.currentPage);
      this.prevPage = getPrevOrLastPage(this.allPages, this.currentPage);
    }
  }

  handleClickEvent(e: MouseEvent) {

    const target = e.target as HTMLElement;
    switch(e.type) {
      case 'click':
        switch(target?.id) {

          case 'prev-page-link':
            if(this.prevPage) {
              e.preventDefault();
              this.goToPrev();
            }
            break;

          case 'next-page-link':

            if(this.nextPage) {
              e.preventDefault();
              this.goToNext();
            }
            break;
        }
        break;
    }
  }
}

function getPrevOrLastPage(pages: Array<Graduate>, page: Graduate): Graduate {

  var currentIndex = pages.map(function (x) { return x.url; }).indexOf(page.url);
  var len = pages.length;
  var prevIndex = (currentIndex+len-1)%len;
  var prevPage = pages[prevIndex];

  return prevPage;
}

function getNextOrFirstPage(pages: Array<Graduate>, page: Graduate): Graduate {

  var currentIndex = pages.map(function (x) { return x.url; }).indexOf(page.url);
  var len = pages.length;
  var nextIndex = (currentIndex+1)%len;
  var nextPage = pages[nextIndex];

  return nextPage;
}

function getPageByUrl(pages: Array<Graduate>, url: string) {

  var currentIndex = pages.map(function (x) { return x.url; }).indexOf(url);
  return pages[currentIndex];
}

function setLoading() {
  document.body.classList.remove('get-ready');
  document.body.classList.add('loading');
}

function unsetLoading() {
  document.body.classList.remove('loading','get-ready');
}

function htmlToHTMLElement(html: string): HTMLElement | null {
  var template = document.createElement('template');
  html = html.trim(); // Never return a text node of whitespace as the result
  template.innerHTML = html;
  let childNode = template.content.firstChild
  if(childNode instanceof HTMLElement) {
    return childNode;
  } else {
    return null;
  }
}