export type GraduateCover = {
  src: string | null,
  srcset: string | null,
  sizes: string
}

export type GraduateContent = {
  cover: GraduateCover | HTMLImageElement;  
  gallery: string;
  websites: string;
  studyCv: string;
  text: string;
  homeLink: string;
  langLink: string;
}

export class Graduate {

  //jsem schopen dostat z tabulky
  index: number;
  url: string; //pro fetch
  langLink: string;
  title: string; //label aktualni stranky nezavisla na fetchi
  year: string; //kvuli stylum
  department: string; 

  //fetch
  content?: GraduateContent | null;

  prevPageUrl?: string | null;
  nextPageUrl?: string | null;

  prevPage?: Graduate | null;
  nextPage?: Graduate | null;

  //elements
  rowElement: HTMLTableRowElement;

  constructor(data) {
    this.index = data.index;
    this.url = data.url;
    this.langLink = data.translationUrl;
    this.title = data.title;
    this.year = data.year;
    this.department = data.department;

    if(data.rowElement instanceof HTMLTableRowElement) {
      this.rowElement = data.rowElement;
    }
    
    // this.prevPageUrl = data.prevPage.url;
    // this.nextPageUrl = data.prevPage.url;
  }

  async getContent(): Promise<GraduateContent> {

    if (this.content) { 
      return this.content;
    } else {
      this.content = await this.fetchContent();
      return this.content;
    }
  }

  private fetchContent = async (): Promise<GraduateContent> => {

    const response = await fetch(this.url + '.json');

    if (!response.ok) {
      const message = `An error has occured: ${response.status}`;
      throw new Error(message);
    }
    const jsonData = await response.json();

    return {
      cover: jsonData.cover,
      gallery: jsonData.gallery,
      websites: jsonData.websites,
      studyCv: jsonData.studyCv,
      text: jsonData.text,
      homeLink: jsonData.homeLink,
      langLink: jsonData.langLink
    };
  }

  prefetchCover() {

    if(
      this.content?.cover
      && this.content.cover.src
      && this.content.cover.srcset
    ) {

      let prefetchImage = new Image();
      prefetchImage.sizes = this.content?.cover.sizes;
      prefetchImage.src = this.content?.cover.src;
      prefetchImage.srcset = this.content?.cover.srcset;
    }
  }
}