import 'normalize.css';
import 'swiper/scss';
import 'swiper/scss/pagination';
import 'swiper/scss/zoom';
import 'swiper/scss/keyboard';
import './styles/index.scss';

import Swiper, { Navigation, Pagination, Zoom, Keyboard } from 'swiper';
import smoothscroll from 'smoothscroll-polyfill';

import SiteHeader from './modules/site-header';
import ButtonScrollTop from './modules/button-scroll-top';
import GraduatePager from './modules/graduate-pager';

import * as basicLightbox from 'basiclightbox';
import * as Sentry from '@sentry/browser';

let siteHeader: SiteHeader;
let swiper: Swiper;
let graduatePager: GraduatePager;
let buttonScrollTop: ButtonScrollTop;

function init() {
  
  //swiper gallery
  if(document.querySelector('.section-gallery'))
  {
    swiper = new Swiper('.section-gallery', {
      zoom: true,
      modules: [Navigation, Pagination, Zoom, Keyboard],
      pagination: {
        el: '.swiper-pagination',
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      keyboard: {
        enabled: true,
        onlyInViewport: true
      },
      lazyPreloadPrevNext: 2
    });
  }

  if(
    document.getElementById('prev-next-control')
    && swiper
  ) {
    graduatePager = new GraduatePager('prev-next-control', swiper);
  }

  if(document.getElementById('site-header'))
  {
    siteHeader = new SiteHeader('site-header');
  }

  if(document.getElementById('button-scroll-top'))
  {
    buttonScrollTop = new ButtonScrollTop('button-scroll-top');
  }

  smoothscroll.polyfill();

  const lightboxes = document.querySelectorAll("a[data-lightbox]");
  if(lightboxes) {
    lightboxes.forEach(element => {
      let lightbox = element as HTMLAnchorElement;
      lightbox.onclick = (e) => {
        e.preventDefault();
        basicLightbox.create(
          `<img src="${lightbox.href}">`,
          {
            onShow: (instance) => {
              document.body.classList.add('lightbox-open');
            },
            onClose: (instance) => {
              document.body.classList.remove('lightbox-open');
            }
          }
        ).show();
      };
    });
  }

  if(Sentry) {
    Sentry.init({
      dsn: import.meta.env.VITE_SENTRY_DSN,
      environment: import.meta.env.VITE_SENTRY_ENV,
      tunnel: '/sentry',
      sampleRate: 1,
      tracesSampleRate: 0,
      sendClientReports: false,
      autoSessionTracking: false
    });
  }
}

function unload() {

  if(siteHeader) {    
    siteHeader.destroy();
  }

  if(swiper) {
    swiper.destroy();
  }

  if(graduatePager) {
    graduatePager.destroy();
  }

  if(buttonScrollTop) {
    buttonScrollTop.destroy();
  }
}

// run once when page loads
if (document.readyState === 'complete') {
  init();
} else {
  document.addEventListener('DOMContentLoaded', () => init());
}
