export default class ButtonScrollTop {

  offset: number = 800;
  scrollHandler: EventListener;
  resizeHandler: EventListener;
  buttonScrollTop: HTMLElement | null;

  constructor(id: string) {

    this.buttonScrollTop = document.getElementById(id);

    this.recalcOffset();

    if(this.buttonScrollTop) {

      this.scrollHandler = this.handleScrollEvent.bind(this);
      this.resizeHandler = this.handleResizeEvent.bind(this);
      this.enable();
    }
  }

  recalcOffset() {
    if(window.outerHeight) {
      this.offset = window.outerHeight * 0.7;
    }
  }

  handleScrollEvent(e: Event) {
  
    let currentScrollTop = document.documentElement.scrollTop || document.body.scrollTop;

    if (currentScrollTop > this.offset) {
      this.buttonScrollTop!.hidden = false;
    } else {
      this.buttonScrollTop!.hidden = true;
    }
  }

  handleResizeEvent() {
    this.recalcOffset();
  }

  enable() {
    window.addEventListener('scroll', this.scrollHandler);
    window.addEventListener('resize', this.resizeHandler);
  }

  destroy() {
    window.removeEventListener('scroll', this.scrollHandler);
    window.removeEventListener('resize', this.resizeHandler);
  }
}