import List from 'list.js';
import Dropdown from './dropdown';

export default class GraduatesDropdown extends Dropdown {

  siteSearchInput: HTMLInputElement | null;
  graduateList: List;
  tbodyElement: HTMLElement;

  constructor(id: string) {

    super(id);

    const siteSearchInput = document.getElementById('graduates-search');
    if(siteSearchInput instanceof HTMLInputElement) {
      this.siteSearchInput = siteSearchInput;
      this.siteSearchInput.hidden = false;
    }
    this.focusedElement = this.siteSearchInput;

    this.graduateList = new List('graduates-list', {
      valueNames: [
        { attr: 'data-value', name: 'year' },
        { attr: 'data-value', name: 'department' },
        { attr: 'data-value', name: 'name' }
      ]
    });

    //scroll to top when search – otherwise results are not visible
    //this is because of the scrolling of tbody
    this.graduateList.on('searchStart', () => {
      this.tbodyElement?.scrollTo(0,0);
    });

    const tbodyElement = this.listElement?.querySelector('tbody.list');
    if(tbodyElement instanceof HTMLElement) {
      this.tbodyElement = tbodyElement;
    }

	}
  
  reset() {
    this.tbodyElement?.scrollTo(0,0);
    // this.graduateList.search();
    // this.graduateList.sort('name');
    //this.siteSearchInput!.value = "";
  }
}