export default class Dropdown {

  id: string;
  openClass: string;
  listElement: HTMLElement | null;
  buttonElement: HTMLElement | null;
  isOpen: boolean = false;
  focusedElement: HTMLElement | null;
  focusOutHandler: EventListener;

  constructor(id: string) {
    this.id = id;
    this.openClass = 'dropdown-open-' + id;
    this.listElement = document.getElementById(id + '-list');
    this.focusedElement = this.listElement;
    this.buttonElement = document.getElementById('button-open-' + id);
    this.focusOutHandler = this.handleFocusOutEvent.bind(this);
    this.enable();
	}

  enable() {
    //this.listElement!.addEventListener('focusout', this.focusOutHandler, true);
  }

  destroy() {
    //this.listElement!.removeEventListener('focusout', this.focusOutHandler);
  }

  open() {
    document.body.classList.add(this.openClass);
    document.body.classList.remove('scrolled-down');
    this.listElement!.hidden = false;
    this.buttonElement?.setAttribute('aria-expanded', 'true');
    this.isOpen = true;
    this.focusedElement!.focus();
  }
  
  close() {
    this.reset();
    this.listElement!.hidden = true;
    this.buttonElement?.setAttribute('aria-expanded', 'false');
    this.isOpen = false;
    document.body.classList.remove(this.openClass);
  }

  toggle() {

    if(this.isOpen) {
      this.close();
    } else {
      this.open();
    }
  }

  reset() {
    this.listElement?.scrollTo(0,0);
  }

  handleFocusOutEvent(e: FocusEvent) {
    
    if (
      e.relatedTarget instanceof HTMLElement
      && (
        this.listElement!.contains(e.relatedTarget)
        ||
        e.relatedTarget?.classList.contains('button-toggle-dropdown')
      )
    ) {
      return;
    } else {
      //to allow next dropdown to open and to not cause flashing
      setTimeout(() => {
        if (this.isOpen) {
          this.close();
        }
      }, 100);
    }
  }
}